if(typeof CRD === 'undefined') {
	var CRD = {};
}

(function() {
	"use strict";
	
	/**
	 * Interchange
	 * @class
	 * @param {Object} options - Options to override default interchange options.
	 */
	
	CRD.Interchange = function(options) {
		
		// Set options and events
		CRD.Utils.setOptions(this, options, this.defaults);
		
		// Variables
		var rules = typeof this.options.rules === 'object' && this.options.rules.length > 0 ?
			this.options.rules :
			null;
		
		// Initializes the object (first, to hook to BreakpointSpy events before it gets initialized)
		this.Interchange();
		
		// Apply base constructor with only rules as an argument)
		CRD.BreakpointSpy.apply(this, [{ rules : rules }]);
		
	};
	
	// Inherits from CRD.ScrollSpy
	CRD.Interchange.inherit(CRD.BreakpointSpy);
	
	// Adds new methods to the object prototype
	CRD.Interchange.prototype = jQuery.extend(CRD.Interchange.prototype, {
		
		/**
		 * Default options
		 * @property {Object}  defaults            - Default options
		 * @property {string}  defaults.selector   - Default delay for debounced event
		 * @property {boolean} defaults.usehdpi    - Use hdpi version for the images?
		 * @property {string}  defaults.hdpisuffix - Suffix for hdpi images (suffix is appended to the image file name)
		 * @property {string}  defaults.rules      - BreakpointSpy rules
		 */
		
		defaults : {
			selector   : '[interchange-data]',
			usehdpi    : false,
			hdpisuffix : '@2x',
			rules      : []
		},
		
		/**
		 * Initializes Interchange object
		 * @constructor
		 * @listen crd.breakpointspy.change
		 * @memberof Interchange
		 * @public
		 */
		
		Interchange : function() {
			
			// Hooks to BreakpointSpy events
			this.changeHandler = function() {
				this.changeSources();
			}.bind(this);
			
			// Hooks to BreakpointSpy change event
			jQuery(this)
				.on('crd.breakpointspy.change', this.changeHandler);
			
		},
		
		/**
		 * Sets current breakpoint
		 * @method changeSOurces
		 * @param {String} breakpoint - Current breakpoint
		 * @fires crd.interchange.before
		 * @fires crd.interchange.after
		 * @memberof Interchange
		 * @private
		 */
		
		changeSources : function() {
			
			// Variables
			var elements  = jQuery(this.options.selector), // Elements to apply the source switch
				isdefault = this.breakpoint.current === 'default'; // Defualt breakpoint?
			
			/**
			 * Triggers event
			 * @event crd.interchange.before
			 */
			
			jQuery(this)
				.trigger('crd.interchange.before', [
					this
				]);
			
			// For each cached element
			elements.each(function(i, element) {
				
				// change source of the element
				this.changeSource(element);
				
			}.bind(this));
			
			// If default breakpoint (no matchmedia detected by BreakpointSpy)
			if(isdefault) {
				
				// destruye los eventos asignados a la ventana
				this.destroy();
				
			}
			
			/**
			 * Triggers event
			 * @event crd.interchange.after
			 */
			
			jQuery(this)
				.trigger('crd.interchange.after', [
					this
				]);
			
		},
		
		/**
		 * Change single image or background source
		 * @method changeSOurce
		 * @param {string} breakpoint - Current breakpoint
		 * @param {Object} element    - Element ot update source
		 * @fires crd.interchange.change
		 * @fires crd.interchange.fail
		 * @memberof Interchange
		 * @public
		 */
		
		changeSource : function(element) {
			
			// Current element
			element = jQuery(element);
			
			// Variables
			var data      = this.gatherData(element), // Gather data from the element
				isdefault = this.breakpoint.current === 'default', // Defualt breakpoint?
				closer    = this[isdefault ? 'getDefault' : 'getCloser'](data), // Closest breakpoint
				src; // Image source
			
			// If image source is defined
			if(closer.src !== false) {
				
				// Set element source
				src = closer.src;
				
				// Append sufix for hdpi mode
				if(this.options.usehdpi === true && this.breakpoint.hdpi === true) {
					
					// Adds hdpi suffix to image name (image.jpg > image@2x.jpg)
					src = closer.src.substr(0, closer.src.lastIndexOf('.')) + this.options.hdpisuffix + closer.src.substr(closer.src.lastIndexOf('.'));
					
				}
				
				// cambia el src o bgimage
				this.updateElement(element, src);
				
				/**
				 * Triggers event
				 * @event crd.interchange.change
				 */
				
				element
					.trigger('crd.interchange.change', [
						src,
						this
					]);
				
			}
			else {
				
				/**
				 * Triggers event
				 * @event crd.interchange.fail
				 */
				
				element
					.trigger('crd.interchange.fail', [
						this
					]);
				
			}
			
		},
		
		/**
		 * Gathers sources data from element
		 * @method gatherData
		 * @param {Object} element - Element to gather data for
		 * @returns {Object}
		 * @memberof Interchange
		 * @public
		 */
		
		gatherData : function(element) {
			
			// Parse and returns Interchange specific data tag (JSON)
			return jQuery.parseJSON(jQuery(element)
				.attr('interchange-data')
				.replace(/'/g, '"'));
			
		},
		
		/**
		 * Updates element sources (source image or background image)
		 * @method updateElement
		 * @param {Object} element - Element to perform source interchange
		 * @param {String} src     - Source of the image to interchange
		 * @memberof Interchange
		 * @public
		 */
		
		updateElement : function(element, src) {
			
			// Variables
			var tag = element.prop('tagName')
				.toLowerCase(); // Element tag name
			
			// Switch between different tag names
			switch(tag) {
				
				// Images: change 'src' property value
				case 'img':
					element.attr('src', src);
					break;
				
				// Catch-all: change 'background-image' CSS property value
				default:
					element.css('background-image', src !== 'none' ? 'url(' + src + ')' : 'none');
					break;
				
			}
			
		},
		
		/**
		 * Destroys main events
		 * @method destory
		 * @memberof BreakpointSpy
		 * @public
		 */
		
		destroy : function() {
			
			// Unhook BreakpointSpy events
			CRD.BreakpointSpy.prototype.destroy.apply(this);
			
			// Removes BreakpointSpy change event
			jQuery(this)
				.off('crd.breakpointspy.change', this.changeHandler);
			
		}
		
	});
	
})();